section.section-menu-filter {
    background-color: $color-white;
    padding: 0px 15px;

    div.container-fluid.row {
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
    }

    ul.menu-filter {
          list-style: none;
          padding: 0;
          margin: 0;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;

          li {
              font-size: $font-size-24;
              padding: 0 20px;
              margin: 15px 0;
              line-height: 1;
              border-right: 2px solid $color-black;
              &:last-child{
                  border-right: none;
              }
              a {
                  color: $color-black;
                  &:hover, &:focus {
                      color: $color-1;
                      transition: .4s;
                  }
              }
          }
    }
}
section.gallerie-products {
    background-color: $color-white;
    div.grid-item {
        margin: 10px;
        width: 30%;
        min-width: 250px;
        @media screen and (max-width: $size-md-max){
                width: 40%;
            @media screen and (max-width: $size-slider-breakpoint){

                width: 100%;
                margin: 10px 0;
                padding: 0 15px
            }
        }
    }
}
