.btn {
    background-color: $color-primary;
    transition: all 0.2s ease-in;
    font-size: $font-size-18;
    color: $color-white;
    padding: 4px 10px;
    //width: 100%;
    &.btn-danger {
      background-color: #ec3f3f;
    }
    &.btn-success {
      background-color:#449d44;
    }
    span.btn-em {
        font-size: $font-size-20;
        text-transform: uppercase;
        font-weight: 600;
    }
    &:hover {
        transition: all 0.2s ease-in;
        font-size: $font-size-18;
        background-color: lighten($color-primary, 40%);
        color: $color-black;
    }
}
