// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
@import '../vendors/owl-carousel2/owl.carousel';
@import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}
td.details-control {
    background: url('../images/details_open.png') no-repeat center center;
    cursor: pointer;
}
tr.shown td.details-control {
    background: url('../images/details_close.png') no-repeat center center;
}
/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}


/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
@import 'sections/gallery-products';

//@import 'sections/section-text-image';

//@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
//@import 'modules/module-slideshow';
//@import 'modules/module-header';
//@import 'modules/module-soumission';



/*******************************************************************************
* COMMON TO ALL PAGES
*/

.erreur {
    color: $color-error;
    line-height: 1.5;
    font-weight: 700;
}
main {
    margin-top: $size-nav-height;
    @media screen and (max-width: $size-nav-toggle-breakpoint) {
        margin-top: $size-nav-height-mobile;
    }
}
//.owl-carousel .owl-nav .owl-next {
.owl-next, .owl-prev {
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
}
.owl-next {
    right: 0;
}


  /*  Numbers */
.owl-dots {
    counter-reset: dots;
    display: flex;
    justify-content: flex-end;
}
.owl-dot {
    background-color: $color-1;
    padding: 5px;
    margin-left: 3px;
    font-size: 18px;
    transition: .3s;
    color: $color-black;
    font-weight: 600;
    font-size: $font-size-22;
    &.active {
        background-color: $color-2;
        transition: .3s;
        color: $color-1;
    }
}
.owl-dot:before {
    counter-increment:dots;
    content: counter(dots);
}

@media screen and (max-width:$size-xs-max) {
  .owl-dots {
  		counter-reset: slides-num; /* Initialize counter. */
      position: absolute;
      display: inherit;
      top: 100%;
      left: 50%;
      width: 120px;
      transform: translateX(-50%);
  		&:after {
  			content:  counter(slides-num); /* get total number of items - more info on http://www.sitepoint.com/a-little-known-way-to-replace-some-scripts-with-css-counters/ */
  			display: inline-block;
  			font-size: 20px;
  			font-weight: 700;
  			vertical-align: middle;
        padding-left: 5px;
        position: absolute;
        right: 0;
  		}
  	}

    .owl-dot {
      background-color: none;
      display: inline-block;
    	counter-increment: slides-num; /* Increment counter */
      margin-right: 5px;
      padding: 0;
    	span {
    		display: none;
    	}

    	&.active {
        background-color: none;
        transition: .3s;
        color: none;
    		&:before {
    			content: "Page " counter(slides-num) " /" ; /* Use the same counter to get current item. */
    			display: inline-block;
    			vertical-align: middle;
          font-size: 20px;
          position: absolute;
          left: 0;
          top: 0;
    		}
    	}
    }
    .owl-dot:before {
        counter-increment: none;
        content: none;
        color: $color-black;
        font-weight: 600;
        font-size: $font-size-22;
    }
}

div.product-wrapper {
    border: 2px solid $color-grey;

    div.img {

    }
    div.description-wrapper {
        background-color: $color-grey;
        padding: 15px;


        & > .title-box {
            h4 {
                font-size: $font-size-20;
                line-height: 1.3;
                font-weight: 600;
                color: $color-black;
            }
            h3 {
              font-size: $font-size-30;
              line-height: 1;
              font-weight: 700;
              color: $color-black;
              &.old {
                text-decoration: line-through;
                color: $color-error;
                font-size: $font-size-20;
                text-align: right;
              }
            }
        }

        & > .description {
            padding-top: 15px;
            p, li {
              font-size: $font-size-18;
              line-height: 1.2;
              font-weight: 400;
              color: $color-black;
            }
        }
        div.commande {
            button {
                background-color: $color-1;
                height: 30px;
                color: $color-black;
                font-weight: 600;
                transition: .3s;
                padding: 5px 10px;
                white-space: nowrap;
                &:hover {
                    background-color: $color-2;
                    color: $color-1;
                    transition: .3s;
                }
            }
            input {
                max-width: 75px;
                height: 30px;
            }
        }

    }
}
/*****************************************************/
/* INDEX.PHP
/*****************************************************/

section.section-carousel-accueil {
    padding: 30px 0 25px 0;
    background-color: $color-white;
}


section.section-bursts {
    background-color: $color-2;
    transition: .3s;
    padding: 5px;
    .burst {
      .icon {
        max-width: 50px;
      }
    }
    p {
      color: $color-white;
      text-align: center;
    }
    &.sticky {
      position: fixed;
      transition: .3s;
      left: 0;
      bottom: 0;
      z-index: 3;
      width: 100%;
    }
    @media screen and (max-width: $size-slider-breakpoint){
        position: fixed;
        left: 0;
        bottom: 0;
        z-index: 3;
        width: 100%;
        a {
            max-width: 72px;

        }
    }
}
/*******************************************************************************
* PAGE X
*/
main.page-pannier {
  padding: 50px 0;
  div.buttons-wrapper {}
   td > * {
      margin: auto;
   }
}

main.register-form {
    .register {
      width: 400px;
      background-color: #ffffff;
      box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.3);
      margin: 100px auto;
    }
    .register h1 {
      text-align: center;
      color: #5b6574;
      font-size: 24px;
      padding: 20px 0 20px 0;
      border-bottom: 1px solid #dee0e4;
    }
    .register form {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding-top: 20px;
    }
    .register form label {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
    height: 50px;
      background-color: #3274d6;
      color: #ffffff;
    }
    .register form input[type="password"], .register form input[type="text"], .register form input[type="email"] {
      width: 310px;
      height: 50px;
      border: 1px solid #dee0e4;
      margin-bottom: 20px;
      padding: 0 15px;
    }
    .register form input[type="submit"] {
      width: 100%;
      padding: 15px;
      margin-top: 20px;
      background-color: #3274d6;
    border: 0;
      cursor: pointer;
      font-weight: bold;
      color: #ffffff;
      transition: background-color 0.2s;
    }
    .register form input[type="submit"]:hover {
    background-color: #2868c7;
      transition: background-color 0.2s;
    }
}
// page Nouveautes
.products-gallery {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    transition: .3s;
    .product-wrapper {
        display: inline-block;
        width: 23%;
        transition: .3s;
        min-width: 260px;
        margin: 10px;
        .img {
            overflow: hidden;
            img {
              transition: .5s;
              transform: scale(1);
            }
            &:hover {
              transition: .5s;
                img {
                  transition: .5s;
                  transform: scale(1.2);
                }
            }
        }
    }
}
.pageX {
    section.header {

    }
}
